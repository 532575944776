<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          order="1"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filter_search"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filter_search"
              v-model="tableBudgetCenter.filter.search"
              type="text"
              size="sm"
              placeholder="search here"
              debounce="1000"
              autocomplete="off"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          order="3"
          class="px-1 mb-2"
        >
          <b-button
            size="sm"
            type="button"
            variant="success"
            @click="onCreateBudgetCenter"
          >
            Add Budget Center
          </b-button>
        </b-col>
      </b-row>
      <b-row
        class="mt-1"
      >
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableBudgetCenter"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :stacked="isMobile()"
            :per-page="tableBudgetCenter.perPage"
            :current-page="tableBudgetCenter.currentPage"
            :items="tableBudgetCenterProvider"
            :fields="tableBudgetCenter.fields"
            :sort-by.sync="tableBudgetCenter.sortBy"
            :sort-desc.sync="tableBudgetCenter.sortDesc"
            :sort-direction="tableBudgetCenter.sortDirection"
            :filter="tableBudgetCenter.filter"
            :filter-included-fields="tableBudgetCenter.filterOn"
            :busy="tableBudgetCenter.busy"
          >
            <template #cell(index)="row">
              {{ tableBudgetCenter.currentPage * tableBudgetCenter.perPage - tableBudgetCenter.perPage + (row.index + 1) }}
            </template>

            <template #cell(active)="row">
              {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
            </template>

            <template #cell()="row">
              <div class="text-md-nowrap">
                {{ row.value }}
              </div>
            </template>

            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="success"
                  class="mr-0 mr-md-1 mb-1 mb-md-0"
                  @click="onEditBudgetCenter(row.item)"
                >
                  Edit
                </b-button>
                <b-button
                  size="sm"
                  @click="onShowBudgetApprovers(row.item)"
                >
                  Approvers
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableBudgetCenter.perPage"
            :options="tableBudgetCenter.pageOptions"
            size="sm"
            class="w-100 w-md-25"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableBudgetCenter.currentPage"
            pills
            first-number
            last-number
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
            :total-rows="tableBudgetCenter.totalRows"
            :per-page="tableBudgetCenter.perPage"
          />
        </b-col>

      </b-row>
    </b-container>

    <b-modal
      id="modal-budget-center"
      scrollable
      no-close-on-esc
      no-enforce-focus
      hide-header-close
      no-close-on-backdrop
      :title="ModalBudgetCenterTitle"
    >
      <ValidationObserver
        ref="formBudgetCenter"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="budget code"
                vid="budget_code"
                rules="required|max:30"
              >
                <b-form-group
                  label="Budget Code"
                  label-for="budget_code"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="budget_code"
                    v-model="budgetCenter.budget_code"
                    type="text"
                    autocomplete="off"
                    placeholder="enter budget code here"
                    :disabled="state.busy"
                    :state="errors.length > 0 ? false : null"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                name="budget name"
                vid="budget_name"
                rules="required|max:30"
              >
                <b-form-group
                  label="Budget Name"
                  label-for="budget_name"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <b-input
                    id="budget_name"
                    v-model="budgetCenter.budget_name"
                    type="text"
                    autocomplete="off"
                    placeholder="enter budget name here"
                    :disabled="state.busy"
                    :state="errors.length > 0 ? false : null"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              v-if="state.editing"
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="is_active"
                  v-model="budgetCenter.active"
                  switch
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="onValidateBudgetCenter"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <b-modal
      id="modal-budget-approvers"
      title="Budget Approvers"
      size="xl"
      scrollable
      no-close-on-esc
      no-enforce-focus
      hide-header-close
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <b-container
        class="scrollable-150px px-4"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="px-1"
            order="2"
            order-md="1"
          >
            <b-form-group
              label="Search"
              label-for="filter_budget_approvers_search"
              label-class="font-weight-bolder"
            >
              <b-input
                id="filter_budget_approvers_search"
                v-model="tableBudgetApprovers.filter.search"
                type="text"
                placeholder="search here"
                debounce="1000"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="px-1"
            order="1"
            order-md="2"
          >
            <b-form-group
              label="Budget Code"
              label-for="filter_budget_code"
              label-class="font-weight-bolder"
              class="mb-2"
            >
              <b-input
                id="filter_budget_code"
                v-model="budgetApprover.budget_code"
                type="text"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="px-1"
            order="1"
            order-md="2"
          >
            <b-form-group
              label="Budget Name"
              label-for="filter_budget_name"
              label-class="font-weight-bolder"
              class="mb-2"
            >
              <b-input
                id="filter_budget_name"
                v-model="budgetApprover.budget_name"
                type="text"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="px-1"
            order="1"
            order-md="2"
          >
            <ValidationObserver
              ref="formBudgetApprover"
            >
              <form
                novalidate
                @submit.prevent
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="budget approver"
                  vid="user"
                  rules="required"
                >
                  <b-form-group
                    label="Budget Approver"
                    label-for="budget_approver"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input-group
                      :class="(`flex-grow-1 ${errors.length > 0 ? 'is-invalid' : ''}`)"
                    >
                      <v-select
                        v-model="selected.budgetApprover"
                        input-id="budget_approver"
                        type="text"
                        append-to-body
                        label="approver_name"
                        placeholder="search budget approver here"
                        :calculate-position="calculateDropPosition"
                        :options="lists.budgetApprovers"
                        :class="(`flex-grow-1 ${errors.length > 0 ? 'is-invalid' : ''}`)"
                        :disabled="(fetching.budgetApprovers || state.busy)"
                        :loading="fetching.budgetApprovers"
                      >
                        <template #no-options="">
                          no available budget approver
                        </template>
                      </v-select>
                      <b-input-group-append>
                        <b-button
                          class="pl-2 pr-2"
                          size="sm"
                          variant="success"
                          style="border-radius: 0px 5px 5px 0px"
                          @click="onValidateBudgetApprover"
                        >
                          Add Approver
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </form>
            </ValidationObserver>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="12"
            class="px-1"
          >

            <b-table
              ref="tableBudgetApprovers"
              hover
              small
              striped
              bordered
              responsive
              show-empty
              :stacked="isMobile()"
              :per-page="tableBudgetApprovers.perPage"
              :current-page="tableBudgetApprovers.currentPage"
              :fields="tableBudgetApprovers.fields"
              :items="tableBudgetApproversProvider"
              :sort-by.sync="tableBudgetApprovers.sortBy"
              :sort-desc.sync="tableBudgetApprovers.sortDesc"
              :sort-direction="tableBudgetApprovers.sortDirection"
              :filter="tableBudgetApprovers.filter"
              :filter-included-fields="tableBudgetApprovers.filterOn"
              :busy="tableBudgetApprovers.busy"
            >
              <template #cell(index)="row">
                {{ tableBudgetApprovers.currentPage * tableBudgetApprovers.perPage - tableBudgetApprovers.perPage + (row.index + 1) }}
              </template>

              <template #cell(active)="row">
                {{ Number(row.value) === 1 ? 'Yes' : 'No' }}
              </template>

              <template #cell(approver_name)="{ item }">
                <div class="text-md-nowrap">
                  {{ item?.user?.first_name }} {{ item?.user?.last_name }}
                </div>
              </template>

              <template #cell()="row">
                <div class="text-md-nowrap">
                  {{ row.value }}
                </div>
              </template>

              <template #cell(action)="row">
                <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                  <b-button
                    v-if="Number(row.item.active) === 1"
                    size="sm"
                    variant="danger"
                    @click="onPutBudgetApprover(row.item, 0)"
                  >
                    Deactivate
                  </b-button>
                  <b-button
                    v-else
                    size="sm"
                    variant="success"
                    @click="onPutBudgetApprover(row.item, 1)"
                  >
                    Activate
                  </b-button>
                </div>
              </template>

              <template #table-busy>
                <div class="text-center py-5">
                  <b-icon
                    icon="stopwatch"
                    font-scale="5"
                    animation="cylon"
                  />
                  <p class="h3 py-2">
                    <strong>Loading . . .</strong>
                  </p>
                </div>
              </template>

            </b-table>

          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mt-1 px-1"
          >
            <b-select
              v-model="tableBudgetApprovers.perPage"
              :options="tableBudgetApprovers.pageOptions"
              size="sm"
              class="w-100 w-md-25"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
          >
            <b-pagination
              v-model="tableBudgetApprovers.currentPage"
              pills
              last-number
              first-number
              prev-text="Prev"
              next-text="Next"
              aria-controls="table"
              :total-rows="tableBudgetApprovers.totalRows"
              :per-page="tableBudgetApprovers.perPage"
            />
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import QUERY from 'lodash'
import { core } from '@/config/pluginInit'
import { BOBudgetCenterService, BOBudgetApproverService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'BoMaintenancesBudgetCenters',

  middleware: ['auth', 'bo'],

  mixins: [misc, formatter],

  metaInfo: () => ({
    title: 'Budget Center'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false
      },
      fetching: {
        budgetApprovers: false
      },
      lists: {
        budgetApprovers: []
      },
      selected: {
        budgetApprover: null
      },
      budgetCenter: {
        id: null,
        budget_code: null,
        budget_name: null,
        active: 1
      },
      tableBudgetCenter: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 6, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'budget_code' },
          { mobile: 3, key: 'budget_name' },
          { mobile: 4, key: 'active', class: 'text-center' },
          { mobile: 5, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      },
      budgetApprover: {
        user: null,
        budget_center: null,
        budget_code: null,
        budget_name: null,
        active: 1
      },
      tableBudgetApprovers: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: null
        },
        filterOn: [],
        busy: false,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 5, key: 'action' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'approver_name' },
          { mobile: 3, key: 'active', class: 'text-center' },
          { mobile: 4, key: 'updated_at', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalBudgetCenterTitle () {
      return this.state.editing ? 'Edit Budget Center' : 'Add Budget Center'
    }
  },

  watch: {
    'selected.budgetApprover' (budgetApprover) {
      this.budgetApprover.user = budgetApprover?.id || null
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    async tableBudgetCenterProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableBudgetCenter.busy = true
      return await BOBudgetCenterService.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search
        })
      ).then(({ data }) => {
        this.tableBudgetCenter.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableBudgetCenter.busy = false
      })
    },

    onCreateBudgetCenter () {
      this.state.editing = false
      this.budgetCenter.id = 0
      this.budgetCenter.budget_code = null
      this.budgetCenter.budget_name = null
      this.budgetCenter.active = 1
      this.$bvModal.show('modal-budget-center')
    },

    onEditBudgetCenter (budgetCenter) {
      this.state.editing = true
      this.budgetCenter.id = budgetCenter.id
      this.budgetCenter.budget_code = budgetCenter.budget_code
      this.budgetCenter.budget_name = budgetCenter.budget_name
      this.budgetCenter.active = budgetCenter.active
      this.$bvModal.show('modal-budget-center')
    },

    async onValidateBudgetCenter (event) {
      event.preventDefault()
      await this.$refs.formBudgetCenter.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: () => {
                if (this.state.editing) {
                  return '<h6>Save your Changes on this Budget Center?</h6>'
                }
                return '<h6>Create this Budget Center?</h6>'
              },
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPutBudgetCenter()
                }
                return this.onPostBudgetCenter()
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPostBudgetCenter () {
      return new Promise(resolve => {
        BOBudgetCenterService.post(this.budgetCenter).then(
          ({ data: { message } }) => {
            this.$refs.tableBudgetCenter.refresh()
            this.swalSuccess(message).then(() => {
              this.$bvModal.hide('modal-budget-center')
            })
            resolve(message)
          }
        ).catch(
          ({ message }) => {
            this.$refs.formBudgetCenter.setErrors(message)
            resolve(message)
          }
        ).finally(() => {
          this.state.busy = false
        })
      })
    },

    async onPutBudgetCenter () {
      return new Promise(resolve => {
        BOBudgetCenterService.put(this.budgetCenter).then(
          ({ data }) => {
            const tableBudgetCenterRow = QUERY.find(this.$refs.tableBudgetCenter.localItems, { id: data.budget_center.id })
            if (tableBudgetCenterRow) {
              tableBudgetCenterRow.budget_code = data.budget_center.budget_code
              tableBudgetCenterRow.budget_name = data.budget_center.budget_name
              tableBudgetCenterRow.active = data.budget_center.active
              tableBudgetCenterRow.updated_at = data.budget_center.updated_at
            }
            this.swalSuccess(data.message).then(() => {
              this.$bvModal.hide('modal-budget-center')
            })
            resolve(data)
          }
        ).catch(
          ({ message }) => {
            this.$refs.formBudgetCenter.setErrors(message)
            resolve(message)
          }
        ).finally(() => {
          this.state.busy = false
        })
      })
    },

    async tableBudgetApproversProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableBudgetApprovers.busy = true
      return await BOBudgetApproverService.get(
        this.objectToUrl({
          page: currentPage,
          per_page: perPage,
          sort: sortBy,
          sort_desc: sortDesc,
          filter_text: filter.search,
          budget_center: this.budgetApprover.budget_center
        })
      ).then(({ data }) => {
        this.tableBudgetApprovers.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableBudgetApprovers.busy = false
      })
    },

    async getApprovers () {
      this.state.busy = this.fetching.budgetApprovers = true
      this.selected.approvedBy = null
      this.lists.budgetApprovers = []
      return await SharedListService.getApprovers().then(
        ({ data }) => {
          this.lists.budgetApprovers = data.map(
            approver => ({
              id: approver.id,
              approver_name: `${approver.first_name} ${approver.last_name}`
            })
          )
          this.state.busy = this.fetching.budgetApprovers = false
        }
      )
    },

    onShowBudgetApprovers (budget) {
      this.tableBudgetApprovers.filter.search = null
      this.budgetApprover.budget_center = budget.id
      this.budgetApprover.budget_code = budget.budget_code
      this.budgetApprover.budget_name = budget.budget_name
      this.selected.budgetApprover = null
      this.getApprovers()
      this.$bvModal.show('modal-budget-approvers')
    },

    async onValidateBudgetApprover (event) {
      event.preventDefault()
      await this.$refs.formBudgetApprover.validate().then(
        async allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirm({
              html: '<h6>Add this Budget Approver?</h6>',
              preConfirm: () => {
                this.state.busy = true
                return this.onPostBudgetApprover()
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    onPostBudgetApprover () {
      return new Promise(resolve => {
        BOBudgetApproverService.post(this.budgetApprover).then(
          ({ data: { message } }) => {
            this.$refs.tableBudgetApprovers.refresh()
            this.selected.budgetApprover = null
            this.$refs.formBudgetApprover.reset()
            this.swalSuccess(message)
            resolve(message)
          }
        ).catch(
          ({ message }) => {
            this.$refs.formBudgetApprover.setErrors(message)
            resolve(message)
          }
        ).finally(() => {
          this.state.busy = false
        })
      })
    },

    onPutBudgetApprover (budgetApprover, active = 0) {
      this.swalConfirm({
        html: () => {
          if (Number(active) === 0) {
            return '<h6>Deactivate this Budget Approver?</h6>'
          }

          return '<h6>Activate this Budget Approver?</h6>'
        },
        confirmButtonText: Number(active) === 0 ? 'Deactivate' : 'Activate',
        preConfirm: () => {
          this.state.busy = true
          return new Promise(resolve => {
            BOBudgetApproverService.put({
              ...this.budgetApprover,
              id: budgetApprover.id,
              user: budgetApprover.id,
              active: active
            }).then(
              ({ data }) => {
                this.swalSuccess(data.message).then(() => {
                  const tableBudgetApproversRow = QUERY.find(
                    this.$refs.tableBudgetApprovers?.localItems, {
                      id: data.budget_approver.id
                    }
                  )
                  if (tableBudgetApproversRow) {
                    tableBudgetApproversRow.active = data.budget_approver.active
                    tableBudgetApproversRow.updated_at = data.budget_approver.updated_at
                  }
                })
                resolve(data.message)
              }
            ).catch(
              ({ message }) => {
                resolve(message)
              }
            ).finally(() => {
              this.state.busy = false
            })
          })
        }
      })
    }

  }
}
</script>
